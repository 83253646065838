import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class IframeService {

	private iframeRef: HTMLIFrameElement;

	setRef(ref: HTMLIFrameElement) {
		this.iframeRef = ref;
	}

	postMessage(data: any, target: string) {
		if (!this.iframeRef) return;
		this.iframeRef.contentWindow.postMessage(data, target);
	}
}
