export const environment = {
	shopname: 'Dev Shop',
	shopdescription: 'Dev Shop YFCommerce',
	production: false,
	analytics: 'XXXX',
	theme: 'tools',
	collectionlayout: 'leftsidebar',
	collectionpagesize: 16,
	productlayout: 'leftsidebar',
	themecolor: '#eb8934',
	logo: 'logo.png',
	shopcover: 'logo.png',
	b2b: false,
	maxprice: 200,
	showpricefilter: false,
	storecode: 'dev',
	leftmenu: true,
	languages: [{
		name: 'English',
		code: 'en'
	}, {
		name: 'Italiano',
		code: 'it'
	}],
	defaultlang: 'it',
	showvat: false,
	showcompany: false,
	defaultcountry: 'Italia',
	onlydefaultcountry: false,
	completenewsletter: true,
	shopURL: 'https://yfc-front.6.elbuild.com',
	baseURL: 'https://yfc-front.6.elbuild.com',
	topbartext: 'Welcome to Our store - Tel. Servizio Clienti/Ufficio Vendite: +39 123-456789',
	copyright: '<a id="pixinventLink" target="blank" href="https://www.elbuild.it/" > https://www.elbuild.it/ </a>',
	ing4url: undefined,
	showdocuments: false,
	baseProductURL: '/product/',
	loginredirecturl: '/user/signin',
	analyticsdebug: true,
	hideCF: false,
	hideVatCode: false,
	hideUnivocoPEC: false,
	showMobilePrefix: false,


};
