import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, SharedDataService } from 'elbuild-ui-lib-core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { delay, filter, map, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Customer, StoreEnv } from 'yf-commerce-model-ui-lib';
import { ShopCartService } from 'yf-commerce-ui-lib';

import { IframeService } from './shared/services/iframe.service';

declare let ga: Function;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {

	loading: boolean = true;
	hidebar: boolean;
	// For Progressbar
	loaders = this.loader.progress$.pipe(
		delay(1000),
		withLatestFrom(this.loader.progress$),
		map(v => v[1]),
	);
	googletagmanager: any;
	dev: boolean;

	constructor(private loader: LoadingBarService,
		private cdr: ChangeDetectorRef,
		@Inject(PLATFORM_ID) private platformId: Object,
		private _sanitizer: DomSanitizer,
		private translate: TranslateService,
		private authService: AuthService,
		private iframeService: IframeService,
		private cartService: ShopCartService,
		private sharedDataService: SharedDataService,
		@Inject(LOCAL_STORAGE) private storage: StorageService,
		public router: Router,
		private titleService: Title) {
		this.sharedDataService.currentCommondata.subscribe((data: any) => {
			if (data?.googletmid) {
				this.googletagmanager = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.googletagmanager.com/ns.html?id=' + data.googletmid);
			}


			if (data?.env !== undefined) {
				this.dev = data.env === StoreEnv.DEV;
			}
		});
	}

	ngAfterViewInit(): void {
		this.router.events.pipe(
			filter(event => {
				const routerUrl = event['urlAfterRedirects'];
				if (routerUrl && typeof routerUrl === 'string') {
					ga('set', 'page', event['urlAfterRedirects']);
					ga('send', 'pageview');
				}
				return event instanceof NavigationEnd;
			}));
	}

	ngOnInit(): void {

		if (isPlatformBrowser(this.platformId)) {
			// FIXME hack per nascondere la progress bar, non mi riesce di capire perché non va via
			setTimeout(t => {
				this.hidebar = true;
				this.cdr.detectChanges();
			}, 6000);
		}

		if (this.sameDomain) {
			if (!this.authService.hasCookie()) {
				window.location.href = environment.baseURL + environment.loginredirecturl;
			} else {
				const uid = this.authService.getUid();
				// 	this.cartService.initShop(environment.storecode);
				this.cartService.loadCart();

				const language = this.storage.get('YF-lang-' + environment.storecode);
				this.sharedDataService.setLanguage(language);
				this.translate.setDefaultLang(language);
				this.translate.use(language);

				this.setTitle();

				this.authService.getUser(uid).subscribe(result => {
					this.authService.setLoggedUser(result);
					this.loading = false;
				}, () => this.loading = false);
			}
		} else {
			this.initIframe();
		}
	}


	setTitle() {
		this.translate.get('usertitle').subscribe((data: string) => {
			const t = data;
			this.titleService.setTitle(t + ' | ' + environment.shopname);
		});
	}

	initIframe() {
		window.addEventListener('message', (event: any) => {
			if (event.origin !== this.baseURL) return;
			const eventData = event.data;
			if (eventData.event === 'getJWT') {
				const data = eventData.data;
				if (!data.token) {
					window.location.href = environment.baseURL + environment.loginredirecturl;
				} else {
					this.authService.setTokenCookie(data.token);
					// 	this.cartService.initShop(environment.storecode);
					this.sharedDataService.setLanguage(data.language);
					this.storage.set('YF-lang-' + environment.storecode, data.language);
					this.translate.setDefaultLang(data.language);
					this.translate.use(data.language);
					this.setTitle();

					this.authService.getUser(data.uid).subscribe((user) => {
						if ((user as unknown as Customer).guest) {
							window.location.href = environment.baseURL + environment.loginredirecturl;
						}
						this.authService.setLoggedUser(user);
						this.loading = false;
					}, () => this.loading = false);
				}
			} else if (eventData.event === 'logout') {
				this.authService.logout();
			}
		}, false);
	}

	onIframeLoad(event: Event) {
		this.iframeService.setRef(<HTMLIFrameElement>event.target);
		this.iframeService.postMessage({
			event: 'getJWT',
			data: {}
		}, this.baseURL);
	}

	get baseURL() {
		return environment.baseURL;
	}

	get sameDomain() {
		return this.baseURL.indexOf(window.location.origin) === 0;
	}

}
