import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { AuthService, CrudService, SharedDataService, thisYear } from 'elbuild-ui-lib-core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VERSION } from 'src/environments/version';
import { Customer, OrderReturnMode, Paymode } from 'yf-commerce-model-ui-lib';
import { RouteGuardService, ShopCartService, WishlistService } from 'yf-commerce-ui-lib';

import { environment } from '../../environments/environment';
import { IframeService } from '../shared/services/iframe.service';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

	@Input() class: string = 'footer-light'; // Default class

	public url: any;
	env = environment;
	loggedUser: Customer;
	public openDashboard: boolean = false;
	selected: string;

	subsections: any[] = [];
	ismobile: boolean;

	logo = environment.logo || 'logo.png';


	currentyear = thisYear();
	topbartext = environment.topbartext;
	copyright = environment.copyright;

	version = VERSION;

	returnmode: OrderReturnMode;
	OrderReturnMode = OrderReturnMode;

	showCards: boolean;

	constructor(private router: Router,
		private sharedDataService: SharedDataService,
		private authService: AuthService,
		private iframeService: IframeService,
		private cartService: ShopCartService,
		private guardService: RouteGuardService,
		private wishlistService: WishlistService,
		private customerService: CrudService<Customer>,
		protected deviceService: DeviceDetectorService) {

		this.ismobile = this.deviceService.isMobile() || this.deviceService.isTablet();


		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.url = event.url;
				this.openDashboard = false;
			}
		});

		this.customerService.getEntity(Customer, 'customer', this.authService.getUid()).subscribe(data => {
			this.loggedUser = plainToClass(Customer, data);
		});



		this.sharedDataService.currentCommondata.subscribe(data => {
			if (data) {
				this.returnmode = data.returnmode;
				if (data['allowedpayments'] != undefined)
					this.showCards = !!(data.allowedpayments as string).split(',').find(s => s === Paymode.STRIPE);

				this.setSubsections();

			}
		});
	}





	setSubsections(): void {

		this.selected = this.router.url.split('/').pop();

		const subsections0 = [
			{
				title: 'account',
				label: 'account'
			},
			{
				title: 'shippingaddress',
				label: 'my-shipping-address'
			},
			{
				title: 'billingaddress',
				label: 'my-billing-address'
			},
			{
				title: 'orders',
				label: 'my-orders'
			}];

		if (environment.showdocuments) {
			subsections0.push({
				title: 'documents-history',
				label: 'b2b.documents-history'
			});
		}

		const subsections1 = [{
			title: 'returns',
			label: 'my-order-returns'
		},
		{
			title: 'profile',
			label: 'profile'
		}];

		const subsections2 = [
			{
				title: 'change-password',
				label: 'change-password'
			}
		];

		const sectionCard = [
			{
				title: 'cards',
				label: 'my-cards'
			},
		];

		if (this.returnmode === OrderReturnMode.OFF) {
			this.subsections = [...subsections0, ...subsections2];
		} else {
			this.subsections = [...subsections0, ...subsections1, ...subsections2];
		}

		if (this.showCards) {
			this.subsections = [...this.subsections, ...sectionCard];
		}


	}

	ngOnInit(): void {
		// Change color for this layout
		document.documentElement.style.setProperty('--theme-deafult', environment.themecolor);





	}

	ngOnDestroy(): void {
		// Remove Color
		document.documentElement.style.removeProperty('--theme-deafult');
	}

	ToggleDashboard() {
		this.openDashboard = !this.openDashboard;
	}

	logout(): void {
		this.authService.asynclogout().subscribe(() => {
			this.cartService.clearData();
			this.wishlistService.clearData();
			this.guardService.reset();
			this.cartService.initShop(environment.storecode);
			this.wishlistService.initShop(environment.storecode);
			window.location.href = environment.baseURL;

			/*this.iframeService.postMessage({ event: 'logout' }, environment.baseURL);
*/
		});


	}


	get baseURL() {
		return environment.baseURL;
	}


	handleClickSubsection(sub: any) {
		this.selected = sub.title;
		this.router.navigateByUrl('/account' + (sub.title == 'account' ? '' : '/' + sub.title));
	}

}
