<div
	class="devenv"
	*ngIf="dev">
	DEV ENVIRONMENT - AMBIENTE DI TEST
</div>


<ngx-loading-bar *ngIf="!hidebar"></ngx-loading-bar>




<ng-template [ngIf]="!loading">
	<router-outlet></router-outlet>
</ng-template>




<iframe
	*ngIf="!sameDomain"
	(load)="onIframeLoad($event)"
	style="display: none;"
	[src]="baseURL + '/sso' | sanitizer: 'resource'"></iframe>
