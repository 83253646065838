<!-- header start -->
<header
	class="sticky"
	[ngClass]="class">
	<div class="mobile-fix-option"></div>
	<div class="top-header">
		<div class="container">
			<div class="row">
				<div class="col-lg-6">
					<div class="header-contact">
						<ul>
							<li>

								<app-cms [slug]="'home-intro'"></app-cms>

							</li>
						</ul>
					</div>
				</div>
				<div class="col-lg-6 text-right">
					<ul class="header-dropdown">
						<li
							class="compare"
							*ngIf="false">
							<a [routerLink]="['/shop/compare']">
								<i
									class="fa fa-random"
									aria-hidden="true"></i>
								Compare
							</a>
						</li>
						<li class="mobile-wishlist">
							<a [href]="baseURL + '/shop/wishlist'">
								<i
									class="fa fa-heart"
									aria-hidden="true"></i>
								Wishlist
							</a>
						</li>
						<li
							class="mobile-account ml-2"
							[ngClass]="{'onhover-dropdown': loggedUser && !ismobile}">
							<a
								[routerLink]="['/user/signin']"
								*ngIf="!loggedUser">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{'buttons.login' | translate}}
							</a>
							<a *ngIf="loggedUser && !ismobile">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{loggedUser.description}}
							</a>
							<a *ngIf="loggedUser && ismobile">
								<i
									class="fa fa-user"
									aria-hidden="true"></i>
								{{loggedUser.description}}
							</a>
							<!-- My Account -->
							<ul
								*ngIf="loggedUser && !ismobile"
								class="onhover-show-div">

								<li>
									<a (click)="logout()">Logout</a>
								</li>
							</ul>
						</li>

					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="main-menu border-section border-top-0">
					<div class="brand-logo layout2-logo">
						<a href="{{baseURL}}">
							<!-- <img
								[src]="themeLogo"
								class="img-fluid"
								alt="logo"> -->
							<img
								src="assets/images/icon/{{logo}}"
								class="img-fluid"
								alt="logo">
						</a>
					</div>
					<div class="title">
						<h2>{{'usertitle' | translate}}</h2>
					</div>
					<div class="menu-right pull-right">
						<ul>
							<li>
								<a href="{{baseURL}}">
									{{'backtoshop' | translate}}
									<i class="fas fa-exchange-alt"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
<!-- header end -->

<!-- section start -->
<section class="section-b-space">
	<div class="container">
		<div class="row">
			<div class="col-lg-3">
				<div
					class="account-sidebar"
					(click)="ToggleDashboard()">
					<a
						hre="javascript:void(0)"
						class="popup-btn">
						<i
							aria-hidden="true"
							class="fa fa-bars"></i>
						{{'my-account' | translate}}
					</a>
				</div>
				<div
					class="dashboard-left"
					[class.open]="openDashboard">
					<div
						class="collection-mobile-back"
						(click)="ToggleDashboard()">
						<span class="filter-back">
							<i
								class="fa fa-angle-left"
								aria-hidden="true"></i>
							{{'buttons.close' | translate}}
						</span>
					</div>
					<div class="block-content">

						<ul>
							<li
								*ngFor="let sub of subsections"
								[class.active]="selected == sub.title"
								(click)="handleClickSubsection(sub)">
								<a>{{ sub.label| translate}}</a>

							</li>

							<li *ngIf="false">
								<a href="javascript:void(0)">{{'newsletter' | translate}}</a>
							</li>
							<li
								(click)="logout()"
								class="last">
								<a>{{'buttons.logout' | translate}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col-lg-9">
				<div class="dashboard-right">
					<div class="dashboard">
						<router-outlet></router-outlet>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- footer section -->
<footer class="sticky">
	<div class="sub-footer border-top p-3">
		<div class="container">
			<div class="row">
				<div class="col-xl-8 col-md-9 col-sm-12">
					<div class="footer-end">
						<div class="copyright text-left">
							Copyright  ©  {{currentyear }}
							<span [innerHtml]="copyright"></span>
							, All rights reserved.
							<br>
							<span class="version">
								UI v. {{version.version}} {{env.production ? 'prod' : 'dev'}}
									({{version.hash}} {{version.date | date:'dd/MM/yy'}}
								<span *ngIf="version.tag">tag {{version.tag}}</span>
								)
							</span>
						</div>
					</div>
				</div>
				<div class="col-xl-4 col-md-3 col-sm-12">
					<div class="payment-card-bottom">
						<ul>
							<li>
								<a>
									<img
										src="assets/images/icon/visa.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/mastercard.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/paypal.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/american-express.png"
										alt>
								</a>
							</li>
							<li>
								<a>
									<img
										src="assets/images/icon/discover.png"
										alt>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
