import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardService } from 'yf-commerce-ui-lib';

import { AccountComponent } from './account/account.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'account',
		pathMatch: 'full'
	},
	{
		path: 'account',
		component: AccountComponent,
		loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
		canActivate: [RouteGuardService],
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabled',
		useHash: false,
		anchorScrolling: 'enabled',
		scrollPositionRestoration: 'enabled'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
