import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ElbuildCoreComponentsModule } from 'elbuild-ui-componentslib';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BarRatingModule } from 'ngx-bar-rating';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		CarouselModule,
		BarRatingModule,
		LazyLoadImageModule.forRoot({
			// preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
		}),
		NgxSkeletonLoaderModule,
		TranslateModule,
		ElbuildCoreComponentsModule
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		CarouselModule,
		BarRatingModule,
		LazyLoadImageModule,
		NgxSkeletonLoaderModule,
		TranslateModule
	],
})
export class SharedModule {}
