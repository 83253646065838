import 'reflect-metadata';

import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Inject, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ElbuildCoreComponentsModule, ElbuildUiComponentslibModule } from 'elbuild-ui-componentslib';
import { AuthConfig, BaseAuthModule, ElbuildCoreServicesModule, ElbuildUiLibModule, SharedDataService } from 'elbuild-ui-lib-core';
import { ElbuildUiPaymentslibModule } from 'elbuild-ui-paymentslib';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Customer } from 'yf-commerce-model-ui-lib';
import { setAppInjector, StoreLibConfig, translateBrowserLoaderFactory, YfCommerceUiLibModule } from 'yf-commerce-ui-lib';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';



const authConfig: AuthConfig = {
	cookieprefix: 'YF-customer-' + environment.storecode,
	loginbase: 'access/customer',
	loginpage: 'signin',
	domain: environment.shopURL,
	usertable: 'customer',
	userclass: Customer,
	i18napi: true
};


export const libconfig: StoreLibConfig = {
	routes: undefined,
	homeroute: undefined, // PER PASSARE NUOVO COMPONENTE HOME
	theme: undefined,
	b2b: environment.b2b,
	storecode: environment.storecode,
	productlayout: undefined,
	collectionlayout: undefined,
	myaccountURL: undefined,
	checkoutURL: undefined,
	showpricefilter: undefined,
	showweigthfilter: undefined,
	collectionpagesize: undefined,
	maxprice: undefined,
	brandinfopage: undefined,
	version: undefined,
	completenewsletter: undefined,
	env: environment.production ? 'prod' : 'dev',
	themecolor: environment.themecolor,
	leftmenu: undefined,
	apiprefix: undefined,
	relativeapiurl: undefined,
	apiurl: undefined,
	share: true,
	menu: undefined,
}


@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		HttpClientModule,
		NgbModule,
		LoadingBarHttpClientModule,
		LoadingBarRouterModule,
		ToastrModule.forRoot({
			timeOut: 3000,
			progressBar: false,
			enableHtml: true,
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateBrowserLoaderFactory,
				deps: [HttpClient, TransferState]
			}
		}),
		SharedModule,
		AppRoutingModule,
		YfCommerceUiLibModule.forRoot(libconfig),
		ElbuildUiLibModule.forRoot(authConfig),
		ElbuildUiComponentslibModule.forRoot(),
		ElbuildUiPaymentslibModule.forRoot(),
		ElbuildCoreComponentsModule,
		BaseAuthModule,
		ElbuildCoreServicesModule,
		NgxStripeModule.forRoot(),
		BrowserTransferStateModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(injector: Injector, @Inject(PLATFORM_ID) private platformId: Object, private shareddataservice: SharedDataService) {
		if (isPlatformBrowser(this.platformId)) {
			setAppInjector(injector);
		} {

			this.shareddataservice.addCommonData('analyticsdebug', environment.analyticsdebug);
		}
	}
}
